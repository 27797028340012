<template>
  <el-card class="order-container">
    <template #header>
      <div class="header">
        <el-input
          style="width: 200px; margin-right: 10px"
          :placeholder="'请输入' + searchtitle"
          v-model="searchinfo"
          @change="handleinput"
          clearable
        />
        <!-- <el-button type="primary" size="small" icon="el-icon-edit">修改订单</el-button> -->
        <el-button type="primary" @click="handleConfig()">搜索</el-button>
        <!-- <el-button type="primary" :icon="HomeFilled" @click="handleSend()"
          >出库</el-button
        > -->
        <el-button type="danger" @click="handledeleteall()">删除</el-button>
        <el-button type="success" @click="handleAdd()">新增</el-button>
      </div>
    </template>
    <el-table
      v-loading="loading"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        :prop="item.propname"
        :label="item.name"
        v-for="item in labellist"
        :key="item.name"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="item.propname == 'imageurl'">
            <img style="height: 100px" :src="scope.row[item.propname]" alt="" />
          </div>
          <div
            v-else
            style="height: 100px；  word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /* 超出几行省略 */
            overflow: hidden;
          "
          >
            {{ scope.row[item.propname] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <!-- <el-button
            :type="scope.row.is_start == 0 ? 'success' : 'danger'"
            style="margin-left: 2px"
            @click="handleuplunbo(scope.row)"
            >{{ scope.row.is_start == 0 ? "上架" : "下架" }}</el-button
          > -->
          <el-button
            type="danger"
            style="margin-left: 2px"
            @click="handledelete(scope.row)"
            >删除</el-button
          >
          <el-button
            type="warning"
            style="margin-left: 2px"
            @click="handleedit(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="添加/编辑产品" :visible.sync="dialogFormVisible">
      <div style="overflow: auto; height: 430px">
        <el-form :model="form" :rules="rules" ref="videoliveRef">
          <el-form-item
            prop="longitude"
            label="产品名称"
            required
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.longitude" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            prop="longitude"
            label="产品副标题"
            required
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.longitude" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            prop="isSetPrice"
            label="是否设置价格"
            required
            :label-width="formLabelWidth"
          >
            <el-radio-group v-model="form.isSetPrice">
              <el-radio :label="true">设置价格</el-radio>
              <el-radio :label="false">不设置价格</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="latitude"
            label="产品价格"
            required
            v-show="form.isSetPrice"
            :label-width="formLabelWidth"
          >
            <el-input-number
              v-model="form.latitude"
              @change="handleChange"
              :min="1"
              :max="10"
              label="描述文字"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            prop="isPinDan"
            label="是否拼单"
            required
            :label-width="formLabelWidth"
          >
            <el-radio-group v-model="form.isPinDan">
              <el-radio :label="true">拼单</el-radio>
              <el-radio :label="false">不拼单</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="latitude"
            label="拼单价格"
            required
            v-show="form.isPinDan"
            :label-width="formLabelWidth"
          >
            <el-input-number
              v-model="form.latitude"
              @change="handleChange"
              :min="1"
              :max="10"
              label="描述文字"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            prop="latitude"
            label="价格符号"
            required
            v-show="form.isSetPrice || form.isPinDan"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="form.priceType"
              placeholder="请选择"
              style="width: 150px"
            >
              <el-option
                v-for="item in priceTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="type"
            label="产品类型"
            required
            :label-width="formLabelWidth"
          >
            <el-radio-group v-model="form.type">
              <el-radio :label="0">普通产品</el-radio>
              <el-radio :label="1">带航线产品</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            required
            label="产品图片"
            v-show="form.type == 0"
            prop="assets_url"
            :label-width="formLabelWidth"
          >
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            required
            label="选择航线"
            v-show="form.type == 1"
            prop="assets_url"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="form.changeAirRouter"
              multiple
              placeholder="请选择"
              style="width: 300px"
            >
              <el-option
                v-for="item in airRouterOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="dateTime"
            label="日期时间"
            required
            v-show="form.isPinDan"
            :label-width="formLabelWidth"
          >
            <el-date-picker
              v-model="form.dateTime"
              type="datetimerange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['12:00:00', '08:00:00']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            prop="userParams"
            label="用户报名参数"
            required
            :label-width="formLabelWidth"
          >
            <el-cascader
              v-model="form.userParams"
              style="width: 270px"
              :options="userParamsOptions"
              :props="userParamsProps"
              collapse-tags
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item
            prop="latitude"
            label="产品内容"
            required
            :label-width="formLabelWidth"
          >
            <wangEdit></wangEdit>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handlecancle()">取 消</el-button>
        <el-button type="primary" @click="submitAdd((id = null))"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      @current-change="changePage"
    />
  </el-card>
</template>

<script>
import alllist from "@/utils/request"
// import addoreditVue from '@/components/addorEdit.vue'
import { uploadImgServer } from '@/utils/info'
import wangEdit from '@/components/wangEdit.vue'
export default {
  components: {
    // addoreditVue
    wangEdit
  },
  data () {
    return {
      loading: false,
      tableData: [], // 数据列表
      multipleSelection: [], // 选中项
      total: 0, // 总条数
      currentPage: 1, // 当前页
      pageSize: 10, // 分页大小
      searchinfo: '', // 搜索内容
      searchtitle: '产品名称', //搜索框搜索内容
      selectinfo: '', // 下拉框内容
      // 筛选项默认值
      getdatainfo: 'articlelist',
      deletearr: [],
      dialogFormVisible: false,
      uploadImg: uploadImgServer,
      form: {
        longitude: '',
        latitude: '',
        isSetPrice: true,
        priceType: '$',
        isPinDan: true,
        type: 0,
        dateTime: '',
        userParams: '',
        changeAirRouter: ['北京航线1']
      },
      formLabelWidth: '120px',
      labellist: [
        {
          propname: "id",
          name: 'ID'
        },
        {
          propname: "longitude",
          name: '产品名称'
        },
        {
          propname: "latitude",
          name: '产品类型'
        },
        {
          propname: "latitude",
          name: '产品价格'
        },
        {
          propname: "latitude",
          name: '产品图片'
        },
        {
          propname: "latitude",
          name: '产品内容'
        },
        {
          propname: "creat_time",
          name: '时间'
        }
      ],
      rules: {
        propname: [
          { required: 'true', message: '请输入', trigger: ['change'] }
        ]
      },
      priceTypeOptions: [
        {
          value: '¥',
          label: '¥'
        },
        {
          value: '$',
          label: '$'
        }
      ],
      userParamsProps: { multiple: true },
      userParamsOptions: [
        {
          value: 1,
          label: '学生基本信息',
          children: [
            {
              value: 2,
              label: '姓（中文）',
            },
            {
              value: 3,
              label: '名（中文）',
            },
            {
              value: 4,
              label: 'Last name（英文或拼音）',
            },
            {
              value: 5,
              label: 'First name（英文或拼音）',
            },
            {
              value: 6,
              label: '身份证号',
            },
            {
              value: 7,
              label: '本人身份证照片上传',
            },
            {
              value: 8,
              label: '联络手机',
            }
          ]
        },
        {
          value: 1,
          label: '考试报名信息',
          children: [
            {
              value: 9,
              label: '考试日期',
            },
            {
              value: 10,
              label: '考试场次',
            }
          ]
        }
      ],
      airRouterOptions: [
        {
          value: '北京航线1',
          label: '北京航线1'
        },
        {
          value: '北京航线2',
          label: '北京航线2'
        },
        {
          value: '上海航线2',
          label: '上海航线2'
        }
      ]
    }
  },
  created () {
    this.statusnum = 0
  },
  mounted () {
    // this.getList()
  },
  methods: {
    // 获取列表方法
    getList () {
      this.loading = true
      let data = {
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        searchinfo: this.searchinfo
      }
      alllist.getLvdaolist(data).then((res) => {
        console.log(res)
        this.tableData = res.data.data
        this.total = res.data.total
        this.loading = false
      })
    },
    handleAdd () {
      this.dialogFormVisible = true
    },
    changestatus () {
      this.getList()
    },
    // 触发过滤项方法
    handleinput () {
      this.currentPage = 1
      // getOrderList()
      this.getList()
    },
    // checkbox 选择项
    handleOption (val) {
      console.log(val)
      this.selectinfo = val
      this.getList()
    },
    // checkbox 选择项
    handleSelectionChange (val) {
      let arr = []
      for (var i = 0; i < val.length; i++) {
        arr.push(val[i].id)
      }
      this.deletearr = arr
    },
    // 翻页方法
    changePage (val) {
      console.log(val)
      this.currentPage = val
      this.getList()
    },
    handleedit (row) {
      this.form = row
      this.dialogFormVisible = true
    },
    handlecancle () {
      this.dialogFormVisible = false
      this.$refs.videoliveRef.resetFields()
      this.form = {
        imageurl: null,
        title: '',
        start_time: "",
        videolive_name: ''
      }
    },
    successupload (e) {
      console.log(e)
      this.form.imageurl = e.url
    },
    removeupload () {
      this.form.imageurl = null
    },
    handleuplunbo (row) {
      let data = row
      if (data.is_start == 0) {
        data.is_start = 1
      } else {
        data.is_start = 0
      }
      alllist.updataLvdaolist(data, data.id).then((res) => {
        console.log(res)
        this.$message({
          type: 'success',
          message: data.is_start == 0 ? '下架成功！' : '上架成功！'
        })
      })
    },
    submitAdd () {
      this.$refs.videoliveRef.validate((vaild) => {
        if (vaild) {
          if (this.form.id) {
            alllist.updataLvdaolist(this.form, this.form.id).then((res) => {
              if (res.data != null) {
                this.dialogFormVisible = false
                this.$notify.success({
                  title: '成功！',
                  message: "修改成功！",
                  duration: 500,
                  onClose () {
                    window.location.href = '/Lvdao'
                  }
                })
              }
            })
          } else {
            alllist.postLvdaolist(this.form).then((res) => {
              if (res.data != null) {
                this.$notify.success({
                  title: '成功！',
                  message: "添加成功！",
                  duration: 500,
                  onClose () {
                    window.location.href = '/Lvdao'
                  }
                })
              }
            })
          }
        }
      })
    },
    handledeleteall () {
      if (this.deletearr.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择！'
        })
      } else {
        this.$alert('确认要删除吗？', '删除', {
          confirmButtonText: '确定',
          callback: action => {
            if (action == 'confirm') {
              for (var i = 0; i < this.deletearr.length; i++) {
                let data = {}
                alllist.deleteLvdaolist(data, this.deletearr[i]).then(() => {
                  this.getList()
                })
              }
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
            }
          }
        })
      }
    },
    handledelete (row) {
      this.$alert('确认要删除吗？', '删除', {
        confirmButtonText: '确定',
        callback: action => {
          if (action == 'confirm') {
            let data = {}
            alllist.deleteLvdaolist(data, row.id).then(() => {
              this.$message({
                type: 'success',
                message: '删除成功！'
              })
              this.getList()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.red {
  color: red;
}
.green {
  color: green;
}
</style>